//JS start
$(document).ready(function(){
   /*
    * 初回アクセスだけアニメーション実行
    */
   if( sessionStorage.getItem("access") == null ){
      $('.loading').find('img').on('animationend', function(){
         $('.loading').addClass('complete');
      });
      sessionStorage.setItem("access","on");
   } else {
      $('.loading').addClass('accessed');
   }

   /*
    * Analytics
    */
   (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
   (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
   m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
   })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

   ga('create', 'UA-15153635-1', 'auto');
   ga('send', 'pageview');

   /*
    * Barba.js
    */
   // 現在と同じページのリンクをクリックした場合、リロードをしない設定(オプション)
   // リロードしたい場合は削除
   var links = document.querySelectorAll('a[href]');
   var cbk = function(e) {
    if(e.currentTarget.href === window.location.href) {
      e.preventDefault();
      e.stopPropagation();
    }
   };
   for(var i = 0; i < links.length; i++) {
     links[i].addEventListener('click', cbk);
   }

   // 遷移時に変更したい処理(head内のmetaタグ、個別のJS等)
   Barba.Dispatcher.on('newPageReady', function(currentStatus, oldStatus, barbaContainer, newPageRawHTML) {

      if ( Barba.HistoryManager.history.length === 1 ) {  // ファーストビュー
         return; // この時に更新は必要ありません
      }

      var $newPageHead = $( '<head />' ).html(
         $.parseHTML(
            newPageRawHTML.match( /<head[^>]*>([\s\S.]*)<\/head>/i )[ 0 ],
            document,
            true
         )
      );

      // 変更したいタグ
      var headTags = [
   //      "link[rel='canonical']",
   //      "link[rel='shortlink']",
   //      "link[rel='alternate']",
         "meta[name='description']",
   //      "meta[property^='og']",
   //      "meta[name^='twitter']",
   //      "meta[name='robots']"
      ].join( ',' );
      $('head').find(headTags).remove(); // タグを削除する
      $newPageHead.find(headTags).appendTo('head'); // タグを追加する

      // Google Analyticsにヒットを送信
      if (typeof ga === 'function') {
         ga('send', 'pageview', location.pathname);
      }

      // body classを変更
      var response = newPageRawHTML.replace(/(<\/?)body( .+?)?>/gi, '$1notbody$2>', newPageRawHTML)
      var bodyClasses = $(response).filter('notbody').attr('class')
      $('body').attr('class', bodyClasses)

      // スマホ・タブレットの場合
      if (Foundation.MediaQuery.is('small only')||Foundation.MediaQuery.is('medium only')) {
         // 開いたnavタグを閉じる
         $('.page-header').find('nav').fadeOut();

         //Global Navi内の開いていた子メニューを閉じる
         $('.page-header').find('li').attr('aria-expanded','true');
         $('.page-header').find('li.has-children').find('ul').attr('aria-hidden','true').fadeOut();

         // ハンバーガーボタンを戻す
         $('.humberger-icon').removeClass('open');
      }

      // /index.html Foundation Orbit
      Foundation.Orbit.defaults.animInFromLeft = "fade-in";
      Foundation.Orbit.defaults.animInFromRight = "fade-in";
      Foundation.Orbit.defaults.animOutToLeft = "fade-out";
      Foundation.Orbit.defaults.animOutToRight = "fade-out";
      Foundation.Orbit.defaults.timerDelay = 3000;
      Foundation.Orbit.defaults.pauseOnHover = false;
      var elem = new Foundation.Orbit($('.slide'));

   }); // End Dispatcher

   // アニメーションの設定(フェードアウト→フェードイン)(オプション)
   // 必要ない場合は削除してOKです。
   var fadeTransition = Barba.BaseTransition.extend({
      start: function() {
         //startはトランジションが起動した直後の一番最初に呼び出される。

         //promise.allを使うと、配列で渡された処理が全て終わった後に.thenを実行。
         //この場合は.newContainerLOadingと.fadeOutが終わってから.thenが実行される。
         Promise
            .all([this.newContainerLoading, this.fadeOut()])
            .then(this.fadeIn.bind(this));
      }, // End start function

      fadeOut: function() {
         //古いページコンテンツに対して行う処理。
         //ここでは、animateを使って、fadeoutさせている。
         //return $(this.oldContainer).animate({ opacity: 0 },{duration:'fast'}).promise();
      },

      fadeIn: function() {
         // topに移動(地味に重要)
         document.body.scrollTop = 0;

         //startに記述したallによって、fadeOutが終わったらこのfadeIn関数が呼び出されている。

         var _this = this;
         //ここでのnewContainerは、ajaxで読み込まれてきた新しい方の.barba-containerにあたる。
         var $el = $(this.newContainer);

         //opacity:0;になっていた古いbarba-containerをdisplay:none;に。
         //こちらおそらくfadeIn発動時古いbarba-containerの初期設定として。
         $(this.oldContainer).hide();
         //こちらも新しいbarba-containerの初期設定。
         //visiblityがあるのは、デフォルトではこれがhiddenになってるっぽいから。
         $el.css({
            visibility : 'visible',
            opacity : 0
         });

         $el.animate({opacity: 1}, 200, function() {
            //.done()をつけることで古いbarba-containerのDOMは削除され、transitionが終了する。
            _this.done();
         });
      }
   }); // End BaseTransition

   // returnに作ったトランジションを設定
   Barba.Pjax.getTransition = function() {
      return fadeTransition;
   };

   // barba実行
   $().ready(function(){
      Barba.Pjax.start();
      Barba.Prefetch.init(); // prefetchを有効化
   });

   /*
    * MediaQuery
    */
   if (Foundation.MediaQuery.is('small only')||Foundation.MediaQuery.is('medium only')) {
      $('.page-header').find('.has-children > a').addClass('no-barba')
   }

   // ウィンドウサイズを変更したらイベントを発火
   $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
      // newSize is the name of the now-current breakpoint, oldSize is the previous breakpoint
      // medium, smallサイズではbarba.js を無効化する
      if (newSize == 'medium'||newSize == 'small') {
         $('.page-header').find('.has-children > a').addClass('no-barba')
      } else {
         $('.page-header').find('.has-children > a').removeClass('no-barba')      
      }
   });

   /*
    * Global Navi
    */
   // ハンバーガーボタン
   $('.humberger-icon').click(function(){
      $(this).toggleClass('open');
   });

});
